/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_1 {
  font-size: 3.6rem;
  font-family: $min;
  position: relative;
  font-weight: bold;
  padding: 0 0 0 35px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  @include sp {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0px;
    width: 24px;
    height: 24px;
    background: url(../img/common/ttl-icon.png);
    background-size: cover;
    @include sp {
      top: 17px;
    }
  }
  @include sp {
    font-size: 30px;
    line-height: 1.9;
  }
  span {
    margin: 0 0 0 13px;
    font-size: 18px;
    color: #1163B3;
    font-weight: 600;
    @include sp {
      display: block;
      margin: -10px 0 0;
    }
  }
  .plus {
    width: 18px;
    height: 18px;
    position: absolute;
    @include sp {
      display: none;
    }
    &.plus1 {
      top: 50%;
      transform: translate(-50%,-50%);
      right: -17px;
    }
    &.plus2 {
      top: 50%;
      transform: translate(-50%,-50%);
      right: 258px;
    }
    &.plus3 {
      top: 50%;
      transform: translate(-50%,-50%);
      right: 533px;
    }
    .relative {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      display: block;
    }
    .border {
      content: '';
      width: 18px;
      height: 2px;
      margin: 0 auto;
      top: 50%;
      left: 50%;
      position: absolute;
      background: #e5e5e5;
      transform: translate(-50%, -50%);
      &.border01 {
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transform-origin: center;
      }
    }
  }
}

.c_ttl_2 {
  font-size: 3rem;
  font-family: $min;
  position: relative;
  padding: 0 0 0 35px;
  font-weight: bold;
  @include sp {
    padding: 0 0 0 30px;
  }

  @include sp {
    font-size: 2.2rem;
    line-height: 1.6;
    font-weight: bold;
  }
  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 24px;
    height: 24px;
    background: url(../img/common/ttl-icon.png);
    background-size: cover;
    @include sp {
      top: 7px;
      width: 20px;
      height: 20px;
    }
  }

}

.c_ttl_3 {
  font-size: 2.4rem;
  font-family: $min;
  position: relative;
  padding: 0 0 0 18px;
  font-weight: 600;
  @include sp {
    font-size: 1.8rem;
  }
  &:before {
    width: 3px;
    height: 100%;
    background: #0B5AA0;
    position: absolute;
    content:'';
    top: 0;
    left: 0;
  }
}

/* =================================
 buttons
================================= */
// c_gotop
.c_gotop {
}

.c_btn_1 {
  width: 246px;
  height: 60px;
  background: #0B5CA3;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .2s linear;
  &._another {
    height: 50px;
    &:hover {
      &:before {
        border-right: 1px solid #0B5CA3;
        border-left: none;
      }
    }
    &:before {
      top: 22px;
      border-right: 1px solid #fff;
      border-left: none;
      transform: rotate(45deg);
      left: initial;
      right: 20px;
    }
  }
  @include sp {
    width: 100%;
  }
  &:before {
    position: absolute;
    top: 27px;
    left: 15px;
    width: 7px;
    height: 7px;
    content: '';
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: rotate(-45deg);
    transition: all .2s linear;
  }
  >span {
    font-size: 1.4rem;
    color: #fff;
    transition: all .2s linear;
  }
  &:hover {
    background: #fff;
    outline: 1px solid #0B5CA3;
    >span {
      font-size: 1.4rem;
      color: #0B5CA3;
    }
    &:before {
      border-top: 1px solid #0B5CA3;
      border-left: 1px solid #0B5CA3;
    }
  }
}


.c_btn_2 {
  width: 440px;
  height: 85px;
  background: #3DB46F;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .2s linear;
  @include sp {
    width: 100%;
    height: 80px;
  }
  &:hover {
    background: #fff;
    outline: 2px solid #3DB46F;
    .u_txt_19 {
      color: #3DB46F;
      &:before {
        content: url(../img/common/mail-w-hover.svg);
      }
    }
    &:before {
      border-top: 2px solid #3DB46F;
      border-right: 2px solid #3DB46F;
    }
  }
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 37px;
    right: 20px;
  }
  .u_txt_19 {
    color: #fff;
    padding: 0 0 0 40px;
    position: relative;
    @include sp {
      padding: 0;
    }
    &:before {
      content: url(../img/common/mail-w.svg);
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        left: -40px;
      }
    }
    img {
      margin: 0 10px 0 0;
    }
  }
}

.c_btn_3 {
  overflow: hidden;
  .btnwrap {
    width: 246px;
    height: 50px;
    border: 1px solid #fff;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      right: 10px;
      content:'';
      width: 7px;
      height: 7px;
      border-top: 1px solid $white;
      border-right: 1px solid $white;
      transform-origin: center;
      transform: rotate(45deg) translateX(-50%);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -105%;
      width: calc( 100% + 2px ) ;
      height: calc( 100% + 2px ) ;
      background: $color_2;
      z-index: 1;
      transition: all .2s ease;
    }
  }
  .u_txt_12 {
    position: relative;
    color: $white;
    z-index: 2;
  }
}

.c_btn_4 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  width: 530px;
  height: 80px;
  background: #0B5CA3;
  color: #fff!important;
  font-size: 18px;
  font-family: $min;
  font-weight: bold;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all .2s linear;
  @include sp {
    height: 60px;
  }
  &:hover {
    background: #fff;
    outline: 1px solid #0B5CA3;
    color: #0B5CA3!important;
    &:before {
      content: url(../img/p_company/outlink-h.png);
    }
  }
  &:before {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    content: url(../img/p_company/outlink.svg);
  }
}

.c_btn_5 {
  width: 530px;
  height: 80px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  background: #0B5CA3;
  position: relative;
  transition: all .2s linear;
  align-items: center;
  @include sp {
    height: 60px;
  }
  &:hover {
    background: #fff;
    outline: 1px solid #0B5CA3;
    &:before {
      content: url(../img/p_shoshiki/download-ico-h.png);
    }
    .txt {
      color: #0B5CA3;
      display: block;
      &._xls {
        &:before {
          content: url(../img/p_shoshiki/xls-h.png);
        }
      }
      &._pdf {
        &:before {
          content: url(../img/p_shoshiki/pdf-h.png);
        }
      }
    }
  }
  &:before {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    content: url(../img/p_shoshiki/download-ico.svg);
  }
  .txt {
    padding: 0 0 0 30px;
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    &._xls {
      &:before {
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        content: url(../img/p_shoshiki/xls.svg);
      }
    }
    &._pdf {
      &:before {
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        content: url(../img/p_shoshiki/pdf.svg);
      }
    }
    .mini {
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.5;
    }
  }
}

.c_tel_btn {
  display: -webkit-inline-flex;
  display: -ms-inline-flex;
  display: inline-flex;
  @include sp {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px solid #fff;
    padding: 10px;
    position: relative;
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      position: absolute;
      top: 48%;
      right: 5%;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(-45deg);
    }
  }
  .spteltxt {
    @include sp {
      color: #fff;
      font-size: 12px;
      width: 100%;
      margin: 0 0 5px;
      font-weight: bold;
    }
  }
  img {
    width: 43px;
    height: auto;
    @include sp {
      width: 40px;
    }
  }
  span {
    &.tag {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
    }
    &.telnum {
      font-size: 33px;
      color: #fff;
      font-weight: bold;
      position: relative;
      padding: 0 0 0 25px;
      @include sp {
        font-size: 29px;
      }
      &:before {
        content: url(../img/common/tel-ico-w.svg);
        position: absolute;
        bottom: 0;
        left: 4px;
      }
    }
  }
}

/* =================================
 icon
================================= */
.c_ico_1 {
}

.c_ico_2 {
}

.c_ico_3 {
}

/* =================================
 table
================================= */
.c_table_1 {
}

.c_table_2 {
}

.c_table_3 {
}

/* =================================
 list
================================= */
// c_list_1
.c_list_1 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:after {
    content: '';
    display: block;
    width: 250px;
  }
  &:before {
    order:1;
    content: '';
    display: block;
    width: 250px;
  }
  .list {
    width: 250px;
    @include sp {
      width: 100%;
    }
    &:nth-child(n + 2) {
      @include sp {
        margin: 40px 0 0;
      }
    }
    &:nth-child(n + 5) {
      margin: 30px 0 0;
      @include sp {
        margin: 40px 0 0;
      }
    }
    a {
      display: block;
      .imgconteiner {
        width: 100%;
        height: 140px;
        @include sp {
          height: 52vw;
        }
      }
      img {
        max-width: 100%;
        width: 250px;
        height: 140px;
        object-fit: cover;
        @include sp {
          width: 100%;
          height: 52vw;
        }
      }
      .tags {
        margin: 10px 0 0;
        .tag {
          display: inline-block;
          background: #1163B2;
          color: #fff;
          font-size: 1.2rem;
          font-weight: bold;
          padding: 2px 5px;
        }
      }
      .u_txt_14 {
        font-family: $min;
        color: $black;
        @include sp {
          font-weight: bold;
        }
      }
      .u_txt_16 {
        margin: 15px 0 0;
        position: relative;
        padding: 0 0 0 20px;
        @include sp {
          color: $black;
        }
        &:before {
          content:'';
          width: 7px;
          height: 7px;
          position: absolute;
          top: 5px;
          left: 2px;
          border-top: 1px solid #1163B2;
          border-right: 1px solid #1163B2;
          transform: rotate(45deg);
        }
      }
    }
  }
}

// c_list_2
.c_list_2 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:before {
    content: '';
    width: 350px;
    order: 1;
  }
  &:after {
    content: '';
    width: 350px
  }
  .list {
    margin: 25px 0 0;
    width: 350px;
    box-shadow: 0px 0px 18px -6px rgba(0,0,0,0.3);
    @include sp {
      width: 100%;
      margin: 20px 0 0;
    }
    &:hover {
      .img {
        img {
          transform: scale(1.1) translate(-50%,-50%);
          @include sp {
            transform: translate(-50%,-50%);
          }
        }
      }
    }
    .img {
      display: block;
      width: 100%;
      height: 110px;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%,-50%);
        transform-origin: center center;
        transition: all .2s linear;
      }
    }
    .txtwrap {
      display: block;
      padding: 5px 15px;
      color: $black;
      font-family: $min;
      position: relative;
      &:after {
        position: absolute;
        top: 50%;
        right: 12px;
        content: '';
        width: 9px;
        height: 9px;
        border-bottom: 1px solid #0B5CA4;
        border-right: 1px solid #0B5CA4;
        transform: rotate(-45deg) translate(0, -50%);
        @include sp {
          top: 46%;
        }
      }
    }
  }
}

// c_list_3
.c_list_3 {
}

/* =================================
 item
================================= */
// c_item_1
.c_item_1 {
}

// c_item_2
.c_item_2 {
}

// c_item_3
.c_item_3 {
}

/* =================================
 pager
================================= */
.c_pager_num {
  margin: 40px auto 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;

  >a,
  >span {
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: -ms-inline-flex;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include sp {
      width: 25px;
      height: 25px;
    }

    &.page-numbers {
      font-family: $min;
      font-size: 16px;
      margin: 0 7px 0 0;
      @include sp {
        font-size: 17px;
        margin: 0 5px 0 0;
      }
      &.current {
        background: #0B5CA3;
        color: #fff;
      }
    }
    &.first.page-numbers {
      border: 1px solid #0B5CA3;
      margin: 0 10px 0 0;
      @include sp {
        margin: 0 5px 0 0;
      }
    }
    &.prev.page-numbers {
      border: 1px solid #0B5CA3;
      margin: 0 35px 0 0;
      border: 1px solid #0B5CA3;
      @include sp {
        margin: 0 10px 0 0;
      }
    }
    &.next.page-numbers {
      border: 1px solid #0B5CA3;
      margin: 0 0 0 25px;
      @include sp {
        margin: 0 10px 0 0;
      }
    }
    &.last.page-numbers {
      border: 1px solid #0B5CA3;
      margin: 0 0 0 10px;
      @include sp {
        margin: 0;
      }
    }
  }
}

.c_pager_prev_next {
  margin: 40px 0 0;
  @include sp {
    padding: 0 4.6875%;
  }
  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    width: 800px;
    margin: 0 auto;
    justify-content: space-between;
    @include sp {
      width: 100%;
      flex-wrap: wrap;
    }
  }
  .back {
    @include sp {
      width: 100%;
      margin: 0 0 20px;
    }
  }
  .c_btn_1 {
    height: 50px;
    &:before {
      top: 21px;
    }
    @include sp {
      width: 100%;
      order: 1;
    }
  }
  .arrowbtn {
    width: 120px;
    height: 50px;
    @include sp {
      width: calc(50% - 7px);
    }
  }
  .arrowbtn a {
    width: 120px;
    height: 50px;
    font-size: 14px;
    justify-content: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    border: 1px solid #0B5CA3;
    align-items: center;
    position: relative;
    transition: all .2s linear;
    @include sp {
      width: 100%;
    }
    &:hover {
      background: #0B5CA3;
      color: $white;
      &:before {
        border-color: #fff;
      }
      @include sp {
        background: #fff;
        color: #0B5CA3;
        &:before {
          border-color: #0B5CA3;;
        }
      }
    }
    &:before {
      position: absolute;
      content: '';
      width: 7px;
      height: 7px;
    }
  }
  .prev {
    @include sp {
      order: 2;
    }
  }
  .prev a {
    &:before {
      top: 46%;
      left: 8px;
      transform-origin: center;
      border-bottom: 1px solid #0B5CA3;
      border-left: 1px solid #0B5CA3;
      transform: rotate(45deg) translateY(-50%);
    }
  }
  .next {
    @include sp {
        order: 3;
    }
  }
  .next a {
    &:before {
      top: 44%;
      right: 8px;
      transform-origin: center;
      border-bottom: 1px solid #0B5CA3;
      border-right: 1px solid #0B5CA3;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

}

/* =================================
 hoge
================================= */
.c_hoge {
}
