/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;
  @include pc_tab {
    padding: 0;
    margin: 0 auto;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 1.8em;
  line-height: 1.7;
  letter-spacing: .1em;
  @include sp {
    font-size: 1.6em;
    line-height: 2;
  }
}

.u_txt_2 {
  font-size: 1.6em;
  line-height: 1.7;
  letter-spacing: .1em;
  @include sp {
    font-size: 1.4em;
  }
}

.u_txt_3 {
  font-size: 1.4em;
  line-height: 1.8;
  letter-spacing: .1em;
  @include sp {
  }
}

.u_txt_4 {
  font-size: 1.4em;
  line-height: 1.8;
  letter-spacing: .1em;
  @include sp {
    font-size: 1.8em;
    line-height: 1.4;
  }
}

.u_txt_5 {
  font-size: 1.3em;
  line-height: 1.5;
  letter-spacing: .1em;
  @include sp {
    font-size: 1.5em;
    line-height: 1.4;
  }
}

.u_txt_6 {
  font-size: 1.2em;
}

.u_txt_7 {
  font-size: 3.6em;
  @include sp {
    font-size: 2.8em;
  }
}

.u_txt_8 {
  font-size: 1.8em;
  @include sp {
  }
}

.u_txt_9 {
  font-size: 2.8em;
  @include sp {
    font-size: 2.6em;
  }
}

.u_txt_10 {
  font-size: 2.6em;
  @include sp {
    font-size: 1.8em;
  }
}

.u_txt_11 {
  font-size: 3em;
  @include sp {
    font-size: 2.4em;
  }
}

.u_txt_12 {
  font-size: 1.4em;
}

.u_txt_13 {
  font-size: 1.3em;
  line-height: 1.5;
}

.u_txt_14 {
  font-size: 1.6em;
  line-height: 1.7;
}

.u_txt_15 {
  font-size: 1.8em;
  @include sp {
    font-size: 1.5em;
  }
}

.u_txt_16 {
  font-size: 1.4em;
  @include sp {
    font-size: 1.6em;
  }
}

.u_txt_17 {
  font-size: 1.2em;
  @include sp {
    font-size: 1.4em;
  }
}

.u_txt_18 {
  font-size: 3em;
  @include sp {
    font-size: 2.6em;
  }
}

.u_txt_19 {
  font-size: 1.8em;
  @include sp {
    font-size: 1.9em;
  }
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;
  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

/* =================================
 hoge
================================= */
.no_scroll {
  overflow: hidden;
}



@keyframes fadeOutAnime{
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeUpAnime{
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomAnime{
  from {
    transform: scale(1) translate(-50%,-50%);
  }
  to {
    transform: scale(1.1) translate(-50%,-50%);
  }
}

@keyframes draw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes circleAnime{
  0% {
    opacity: 0;
    top: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 95%;
  }
}
