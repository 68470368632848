/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
  position: relative;
}


.l_verticallines {
  z-index: -1;
  width: 1100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-right: 2px solid rgba(0,0,0,.02);
  border-left: 2px solid rgba(0,0,0,.02);
  pointer-events: none;
  @include sp {
    width: 100%;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
  }
  &-wrap {
    z-index: -1;
    width: 550px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-right: 2px solid rgba(0,0,0,.02);
    border-left: 2px solid rgba(0,0,0,.02);
    pointer-events: none;
    @include sp {
      width: calc(50% + 4px);
    }
    &-box {
      z-index: -1;
      width: 275px;
      height: 100%;
      position: fixed;
      top: 0;
      left: calc(50% - (275px / 2) );
      transform: translateX(-50%);
      border-right: 2px solid rgba(0,0,0,.02);
      pointer-events: none;
    }
  }
}

/* =================================
 l_header
================================= */
.l_header {
  @include sp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    z-index: 3;
  }
  >.inner {
    padding: 25px 25px 0 25px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    @include sp {
      padding: 0;
    }
  }
  .logo {
    >a {
      img {
        pointer-events: all;
        width: 290px;
        @include sp {
          width: 60px;
        }
      }
    }
  }
  .menublk {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    .btnbox {
      @include sp {
        margin: 0 60px 0 0;
      }
      .cvbtn {
        width: 192px;
        height: 60px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color_5;
        .u_txt_13 {
          color: $white;
          position: relative;
          padding: 0 0 0 50px;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            background: url(../img/common/mail.png);
            background-size: cover;
          }
        }
      }
    }
  }
  .menubox {
    padding: 0 30px 0 0;
    @include sp {
      display: none;
    }
    .box1 {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .teltxt {
        margin: 0 0 0 21px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        .tellabel {
          display: inline-block;
          padding: 2px 3px 1px 4px;
          background: #EBDD2D;
          font-size: 11px;
          font-weight: bold;
          .mini {
            font-size: 10px;
          }
        }
        .tel {
          margin: 0 0 0 5px;
          font-size: 24px;
          color: $black;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
        }
      }
    }
    .box2 {
      padding: 18px 0 0;
      .menu {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        >li {
          padding: 0 0 25px;
          &:not(:last-child) {
            margin: 0 25px 0 0;
            @include mq_max(1420) {
              margin: 0 20px 0 0;
            }
          }
          &:hover {
            >a,
            >span {
              color: $color_6;
            }
          }
          >a,
          >span {
            font-size: 14px;
            color: $black;
            transition: all .2s linear;
          }
          >.mega {
            display: none;
          }
        }
      }
    }
  }
  .mega {
    position: absolute;
    top: 115px;
    left: 0;
    width: 100vw;
    padding: 20px;
    z-index: 1;
    background: #fff;
    border-top: 1px solid #E5E5E5;
    ul {
      width: 450px;
      margin: 0 auto;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      >li {
        a {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
          &:hover {
            .u_txt_12 {
              color: $color_6;
            }
          }
          img {
            width: 80px;
          }
          span {
            padding: 0 30px 0 0;
            margin: 0 0 0 10px;
            position: relative;
            color: $black;
            transition: all .2s linear;
            &:before {
              content: '';
              width: 7px;
              height: 7px;
              border-top: 1px solid #0B5CA3;
              border-right: 1px solid #0B5CA3;
              transform: rotate(45deg);
              position: absolute;
              top: 3px;
              right: 0;
            }
          }
        }
      }
    }
  }
  .menubtn {
    display: none;
    @include sp {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 60px;
      height: 60px;
      background: #E9F0F7;
      z-index: 100;
      >.inner {
        width: 100%;
        height: 100%;
        position: relative;
        .border {
          width: 26px;
          height: 3px;
          background: #0B5AA0;
          position: absolute;
          left: 17px;
          transition: all .2s linear;
          &.border1 {
            top: 20px;
            transform-origin: left top;
          }
          &.border2 {
            top: 30px;
            opacity: 1;
            transform-origin: center center;
          }
          &.border3 {
            top: 40px;
            transform-origin: left bottom;
          }
        }
      }
    }
    &._active {
      @include sp {
        .inner {
          .border1 {
            top: 21px;
            left: 21px;
            transform: rotate(45deg);
          }
          .border2 {
            transform: rotate(180deg);
            opacity: 0;
          }
          .border3 {
            top: 39px;
            left: 21px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.l_header_fixed {
  position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  display: none;
  border-bottom: 1px solid #F0F0F0;
  z-index: 2;
  @include sp {
    display: none!important;
  }
  >.inner {
    padding: 5px 10px 0 10px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    >a {
      img {
        pointer-events: all;
        width: 66px;
      }
    }
  }
  .menublk {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    .btnbox {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      .cvbtn {
        width: 206px;
        height: 34px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color_5;
        .u_txt_12 {
          color: $white;
        }
      }
    }
  }
  .menubox {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-end;
    .box2 {
      padding: 26px 0 0;
      .menu {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        width: 100%;
        >li {
          margin: 0 30px 0 0;
          padding: 0 0 25px;
          &:hover {
            >a,
            >span {
              color: $color_6;
            }
          }
          >a,
          >span {
            color: $black;
            transition: all .2s linear;
          }
          >.mega {
            display: none;
          }
        }
      }
    }
  }
  .mega {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vw;
    padding: 20px;
    z-index: 1;
    background: #fff;
    border-top: 1px solid #E5E5E5;
    ul {
      width: 450px;
      margin: 0 auto;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-between;
      >li {
        a {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          align-items: center;
          &:hover {
            .u_txt_12 {
              color: $color_6;
            }
          }
          img {
            width: 80px;
          }
          span {
            padding: 0 30px 0 0;
            margin: 0 0 0 10px;
            position: relative;
            color: $black;
            transition: all .2s linear;
            &:before {
              content: '';
              width: 7px;
              height: 7px;
              border-top: 1px solid #0B5CA3;
              border-right: 1px solid #0B5CA3;
              transform: rotate(45deg);
              position: absolute;
              top: 3px;
              right: 0;
            }
          }
        }
      }
    }
  }
}

.l_menu_sp {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: -100%;
  background: #fff;
  padding: 30px 20px 40px;
  transition: all .1s linear;
  opacity: 0;
  &._active {
    left: 0;
    opacity: 1;
  }
  >.inner {
    >a {
      display: inline-block;
      img {
        max-width: 206px;
      }
    }
    >.wrap {
      margin: 33px 0 0;
      overflow-y: scroll;
      padding: 0 0 40px;
      >ul {
        >li {
          border-bottom: 2px solid #F5F5F5;
          >a,
          >span {
            color: $black;
            display: block;
            padding: 20px 0;
            font-size: 1.6em;
            font-weight: bold;
            transition: color .2s linear;
          }
          >span {
            position: relative;
            &:before {
              content: '';
              width: 12px;
              height: 2px;
              background: #1163B2;
              position: absolute;
              top: 50%;
              right: 10px;
            }
            &:after {
              content: '';
              width: 12px;
              height: 2px;
              background: #1163B2;
              position: absolute;
              top: 50%;
              right: 10px;
              transform-origin: center;
              transform: rotate(90deg);
              transition: all .2s linear;
              opacity: 1;
            }
          }
          &._active {
            padding: 0 0 13px;
            >span {
              color: #1163B2;
              padding: 20px 0;
              position: relative;
              &:after {
                transform: rotate(270deg);
                opacity: 0;
              }
            }
          }
          >ul {
            padding: 0 0 0 20px;
            display: none;
            >li {
              position: relative;
              &.first {
                &:before {
                  content: '';
                  width: 8px;
                  height: 8px;
                  position: absolute;
                  top: 10px;
                  right: 13px;
                  border-top: 1px solid #1163B2;
                  border-right: 1px solid #1163B2;
                  transform: rotate(45deg);
                }
                >a {
                  padding: 5px 0 13px;
                }
              }
              &:before {
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                top: 18px;
                right: 13px;
                border-top: 1px solid #1163B2;
                border-right: 1px solid #1163B2;
                transform: rotate(45deg);
              }
              >a {
                font-weight: bold;
                display: block;
                padding: 13px 0;
                color: $black;
                font-size: 1.6em;
              }
            }
          }
        }
      }
      .c_tel_btn {
        border: 2px solid #1163B2;
        &:first-of-type {
          margin: 30px 0 0;
        }
        &:not(:first-of-type) {
          margin: 20px 0 0;
        }
        .telnum {
          color: #1163B2;
          &:before {
            content: url(../img/common/sp/tel-ico-blue.svg);
          }
        }
        .spteltxt {
          padding: 0 0 0 15px;
          color: #1163B2;
        }
      }
      .c_btn_2 {
        margin: 20px 0 0;
      }
    }

  }
}

/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  width: 100%;
  position: absolute;
  left: 5%;
  top: 10px;
  @include sp {
    position: absolute;
    width: calc( 100% - 4.6875% );
    top: 80px;
    left: 4.6875%;
    white-space: nowrap;
    overflow: scroll;
    padding: 0 0 10px;
  }
  p,span,a {
    font-size: 13px;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      @include sp {
        text-decoration: underline;
      }
    }
  }
}

/* =================================
 l_main_visual_top
================================= */
@keyframes load {
  0% {
    stroke-dashoffset: 0;
  }
}

  svg {
    transform: rotate(-90deg);
  }

  circle {
    fill: transparent;
    stroke: $white;
    stroke-width: 3;
    display: none;
  }

  ._u_circle {
    display: block;
     animation-fill-mode: forwards;
    animation: circle 5.5s linear;
  }



  @keyframes circle {
    0% { stroke-dasharray: 0 366; }
    100%{ stroke-dasharray: 366 366; }
  }


.l_main_visual_top {
  @include sp {
    margin: 60px 0 0;
  }
  >.inner {
    width: 100%;
    position: relative;
    .mainslider {
      li {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        position: relative;
        justify-content: flex-end;
        .txtwrap {
          position: absolute;
          left: 4%;
          top: 31%;
          z-index: 1;
          opacity: 0;
          @include sp {
            top: 40px;
          }
          picture {
            @include sp {
              img {
                max-width: 100%;
              }

            }
          }
          &.u_anime_txt {
            animation-name: fadeUpAnime;
            animation-duration: 2.5s;
            animation-fill-mode:forwards;
          }
          &.u_anime_txt2 {
            animation-name: fadeOutAnime;
            animation-duration: 2s;
            animation-fill-mode:forwards;
          }
        }
        .imgwrap {
          width: 80%;
          height: 648px;
          position: relative;
          overflow: hidden;
          @include sp {
            margin: 160px 0 0;
            width: 100%;
            height: auto;
            padding: 93% 0 0;
            position: relative;
          }
          >img {
            position: absolute;
            max-width: 100%;
            top: 50%;
            left: 50%;
            height: auto;
            transform-origin: center center;
            transform: translate(-50%,-50%);
            backface-visibility: hidden;
	          -webkit-backface-visibility: hidden;
            &.u_pc {
              display: block;
              @include sp {
                display: none;
              }
            }
            &.u_sp {
              display: none;
              @include sp {
                display: block;
              }
            }
            &.u_anime_img {
              animation-name: zoomAnime;
              animation-duration: 10s;
              animation-fill-mode:forwards;
              backface-visibility: hidden;
              -webkit-backface-visibility: hidden;
            }
          }
        }
      }

      .slick-dots {
        position: absolute;
        bottom: 30px;
        right: 200px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        @include sp {
          right: 11px;
          bottom: 13px;
        }
        li {
          color: transparent;
          width: 20px;
          height: 20px;
          position: relative;
          border-radius: 50%;
          &:not(:first-child) {
            margin: 0 0 0 2px;
            &:before {
              left: 53%;
            }
          }
          button {
            position: absolute;
            top: 1px;
            left: 1px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          &:before {
            top: 50%;
            left: 50%;
            content: '';
            width: 4px;
            height: 4px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%,-50%);
          }
          &:after {
            position: absolute;
            content: "";
          }
        }

      }
    }
    .thumbnailslider {
      position: absolute;
      bottom: 30px;
      right: 30px;
      width: 135px;
      height: 92px;
      @include sp {
        display: none;
      }
      .slick-slide {
        width: 135px;
        height: 92px;
        position: relative;
      }
      li {
        img {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .ciecles {
      position: absolute;
      bottom: 27px;
      right: 199px;
      pointer-events: none;
      @include sp {
        right: 10px;
        bottom: 10px;
      }
    }

    .scrollpoint {
      position: absolute;
      bottom: 0;
      left: 10%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include sp {
        left: 5%;
      }
      .sctxt {
        width: 100%;
        font-weight: bold;
        font-family: $min;
        font-size: 12px;
        color: #0B5AA0;
        padding: 0 0 10px;
        @include sp {
          color: #fff;
          transform: rotate(-90deg);
          padding: 0 0 0 10px;
          transform-origin: top left;
          position: absolute;
          white-space: nowrap;
          left: -6px;
        }
      }
      .border {
        width: 2px;
        height: 150px;
        background: #0B5AA0;
        position: relative;
        @include sp {
          height: 80px;
          background: #fff;
        }
        .circle {
          position: absolute;
          width: 8px;
          height: 8px;
          background: #0B5AA0;
          top: 0;
          opacity: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;

          animation-name: circleAnime;
          animation-duration: 2.5s;
          animation-fill-mode:forwards;
          animation-iteration-count: infinite;
          @include sp {
            background: #fff;
          }
        }
      }
    }
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
  position: relative;
  background: url(../img/common/bg_pattern.png);
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom;
  overflow: hidden;
  @include sp {
    padding: 60px 0 0;
    background: none;
  }
  >.inner {
    width: 1100px;
    margin: 0 auto;
    min-height: 294px;
    padding: 0 0 40px;
    box-sizing:content-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sp {
      width: 100%;
      padding: 0;
      min-height: initial;
    }
    .txtwrap {
      @include sp {
        width: 100%;
        padding: 70px 4.6875% 35px;
      }
      .u_txt_14 {
        margin: 10px 0 0;
        font-family: $min;
        font-weight: 600;
        .plus {
          display: inline-block;
          color: #0B5AA0;
          margin: 0 5px;
          font-weight: bold;
        }
      }
      .tags {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        .tag {
          display: inline-block;
          color: $black;
          background: #EBDD2D;
          font-size: 11px;
          padding: 7px 7px 6px;
          font-weight: bold;
        }
        .day {
          padding: 0 0 0 18px;
          font-weight: bold;
          font-size: 14px;
          color: $black;
        }
      }
    }
    .imgwrap {
      top: 0;
      right: 0;
      position: absolute;
      height: 294px;
      width: calc(50vw + 136px);
      min-width: 740px;
      @include sp {
        position: initial;
        top: initial;
        right: initial;
        height: auto;
        width: 100%;
        min-width: initial;
      }
      img {
        @include sp {
          width: 100%;
        }
      }
    }
  }
}

/* =================================
 l_main_visual_underpage
================================= */
.l_main_visual.l_main_visual_underpage {
  position: relative;
  background: url(../img/common/bg_pattern.png);
  background-repeat: repeat-x;
  background-size: contain;
  background-position: bottom;
  overflow: initial;
  margin: 80px 0 0;
  height: 144px;
  @include sp {
    padding: 60px 0 0;
    height: auto;
    background: none;
  }
  .l_breadcrumbs {
    top: -70px;
    @include sp {
      top: 0;
    }
  }
  >.inner {
    width: 1100px;
    margin: 0 auto;
    min-height: auto;
    // padding: 0 0 40px;
    box-sizing:content-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    @include sp {
      width: 100%;
      padding: 0;
      min-height: initial;
      background: url(../img/common/sp/bg_pattern.png);
      background-repeat: repeat-x;
      background-size: contain;
    }
    .txtwrap {
      @include sp {
        width: 100%;
        padding: 23px 4.6875%;
      }
      .c_ttl_1 {
        @include sp {
          line-height: 1.5;
          &:before {
            top: 12px;
          }
        }
      }
      &._center {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
      }
      .u_txt_14 {
        margin: 10px 0 0;
        font-family: $min;
        font-weight: 600;
        .plus {
          display: inline-block;
          color: #0B5AA0;
          margin: 0 5px;
          font-weight: bold;
        }
      }
    }
  }
}

/* =================================
 l_content
================================= */
.l_content {
  padding: 40px 0 80px;
  @include sp {
    padding: 60px 4.6875% 80px;
  }
  .l_main {
    width: 1100px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
}


/* =================================
 l_side
================================= */
.l_side {
}

/* =================================
 l_cv
================================= */
.l_cv {
  width: 100%;
  padding: 0 0 80px;
  @include sp {
    padding: 0 0 40px;
  }
  >.inner {
    margin: 0 auto;
    width: 1100px;
    background: url(../img/common/cvbg.png);
    background-size: cover;
    padding: 130px 120px 130px 110px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sp {
      width: 100%;
      background: url(../img/common/sp/cvbg.png);
      background-size: cover;
      flex-wrap: wrap;
      justify-content: center;
      padding: 60px 4.6875%;
    }
    .left {
      @include sp {
        width: 100%;
      }
      .u_txt_18 {
        color: #fff;
        font-family: $min;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        @include sp {
          font-weight: bold;
          text-align: center;
          display: block;
        }
        span {
          margin: 0 0 0 15px;
          font-size: 18px;
          font-weight: 100;
          @include sp {
            margin: 5px 0 0;
            text-align: center;
            display: block;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
      .u_txt_14 {
        margin: 20px 0 0;
        color: #fff;
        @include sp {
          text-align: center;
          font-weight: bold;
          padding: 0 0 50px;
          position: relative;
          &:before {
            content: '';
            width: 14px;
            height: 2px;
            background: #fff;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:after {
            content: '';
            width: 14px;
            height: 2px;
            background: #fff;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
          }
        }
      }
    }
    .right {
      width: 440px;
      text-align: center;
      @include sp {
        width: 100%;
      }
      .u_txt_17 {
        color: #fff;
        text-align: center;
        margin: 0 0 15px;
        @include sp {
          font-weight: bold;
        }
      }
      .c_tel_btn {
        pointer-events: none;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp {
          pointer-events: all;
          justify-content: flex-start;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          margin: 10px 0 0;
        }
        .tag {
          font-size: 14px;
          font-weight: bold;
          background: #EBDD2D;
          width: 86px;
          height: 27px;
          display: -webkit-inline-flex;
          display: -ms-inline-flex;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $black;
          .sml {
            font-size: 12px;
          }
        }
      }
      .c_btn_2 {
        margin: 20px auto 0;
      }
    }
  }
}

/* =================================
 l_footer
================================= */
.l_footer {
  background: #F7F7F7;
  >.inner {
    width: 1100px;
    margin: 0 auto;
    padding: 50px 0 50px 60px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    @include sp {
      width: 100%;
      padding: 40px calc(4.6875% * 5) 40px 4.6875%;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
  .left {
    a {
      img {
        width: 264px;
      }
    }
    .u_txt_12 {
      line-height: 1.5;
      margin: 15px 0 0;
    }
  }
  .right {
    padding: 20px 0 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    @include sp {
      display: block;
      padding: 0;
      margin: 0 0 50px;
    }
    .col {
      &.col1 {
        margin: 0 60px 0 0;
        @include sp {
          margin: 0;
        }
        >ul {
          >li {
            >a {
              @include sp {
                margin: 25px 0 0;
              }
            }
          }
        }
      }
      &.col2 {
        margin: 0 50px 0 0;
        @include sp {
          margin: 30px 0 0;
        }
      }
      &.col3 {
        @include sp {
          margin: 30px 0 0;
        }
        >ul {
          >li {
            @include sp {
              margin: 25px 0 0!important;
            }
            &._another {
              @include sp {
                margin: 15px 0 0!important;
              }
            }
            >ul {
              >li {
                @include sp {
                  margin: 0;
                }
              }
            }
          }
        }
      }
      >ul {
        &+ul {
          margin: 20px 0 0;
          @include sp {
            margin: 30px 0 0;
          }
        }
        >li {
          &:not(:first-child) {
            margin: 15px 0 0;
            @include sp {
              margin: 0;
            }
          }
          >a {
            color: $black;
            font-size: 14px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            padding: 0 0 0 12px;
            position: relative;
            @include sp {
              font-size: 18px;
            }
            img {
              margin: 0 0 0 5px;
            }
            &:before {
              content: '';
              border-top: 1px solid #0B5AA0;
              border-right: 1px solid #0B5AA0;
              position: absolute;
              width: 6px;
              height: 6px;
              top: 4px;
              left: 0;
              transform: rotate(45deg);
              @include sp {
                top: 7px;
              }
            }
          }
          >ul {
            padding: 0 0 0 10px;
            @include sp {
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              flex-wrap: wrap;
            }
            li {
              margin: 13px 0 0;
              @include sp {
                margin-top: 15px;
              }
              &:nth-child(2n) {
                @include sp {
                  margin: 15px 0 0 30px;
                }
              }
              a {
                font-size: 13px;
                color: $black;
                display: inline-block;
                padding: 0 0 0 10px;
                position: relative;
                @include sp {
                  font-size: 15px;
                }

                span {
                  font-size: 13px;
                  color: $black;
                  opacity: .7;
                  @include sp {
                    font-size: 15px;
                  }

                }
                &:before {
                  content: '';
                  width: 8px;
                  height: 1px;
                  background: #0B5AA0;
                  position: absolute;
                  top: 5px;
                  left: 0;
                  @include sp {
                    top: 8px;
                  }
                }
                img {
                  display: inline-block;
                  margin: 0 0 0 5px;
                  transform: translateY(-2px);
                }
              }
            }
          }
        }
      }
    }
  }
  .copy {
    height: 30px;
    width: 100%;
    color: #fff;
    font-size: 11px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0B5CA3;
  }
}

.l_bg_link {
  margin: 0 0 80px;
  width: 100%;
  height: 300px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  @include sp {
    height: auto;
    margin: 0 0 40px;
  }
  .u_pc_f {
    width: 100%;
  }
  .u_sp {
    width: 100%;
  }
  .box {
    width: 50%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include sp {
      width: 100%;
      padding: 65px 0;
    }
    &:hover {
      &:before {
        transform: scale(1.1);
        @include sp {
          transform: scale(1);
        }
      }
      .c_btn_3 {
        .btnwrap {
          &:after {
            left: -1px;
            @include sp {
              left: -110%;
            }
          }
        }
      }
    }
    .wrap {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .ttl {
      color: #fff;
      font-family: $min;
      padding: 0 0 25px 0;
      position: relative;
      text-align: center;
      width: 100%;
      &:before {
        content: '';
        width: 13px;
        height: 2px;
        background: #fff;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after {
        content: '';
        width: 13px;
        height: 2px;
        background: #fff;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform-origin: center;
        transform: translateX(-50%) rotate(90deg);
      }
    }
    .u_txt_14 {
      margin: 5px 0 0;
      position: relative;
      color: #fff;
      text-align: center;
      width: 100%;
    }
    .c_btn_3 {
      display: inline-block;
      margin: 25px auto 0;
      overflow: hidden;
      @include sp {
        margin: 15px 0 0;
        .btnwrap {
          &:after {
            left: -1px;
          }
        }
      }
    }
    &.box01 {
      @include sp {
        background: url(../img/p_construction/sp/bg01.png);
        background-size: cover;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: url(../img/p_construction/bg01.png);
        background-size: cover;
        background-position: center center;
        transition: all .2s linear;
        @include sp {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% - 10%);
          height: calc(100% - 40px);
          background: #222;
          opacity: .6;
          transform: translate(-50%,-50%);
        }
      }
    }
    &.box02 {
      @include sp {
        background: url(../img/p_construction/sp/bg02.png);
        background-size: cover;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: url(../img/p_construction/bg02.png);
        background-size: cover;
        background-position: center center;
        transition: all .2s linear;
        @include sp {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% - 10%);
          height: calc(100% - 40px);
          background: #222;
          opacity: .6;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}
